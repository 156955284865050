import { template as template_02a1a8be37cb40f89ac4c8b73d735872 } from "@ember/template-compiler";
const FKText = template_02a1a8be37cb40f89ac4c8b73d735872(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
