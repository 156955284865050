import { template as template_98c06fe2fa7e4d41977bfa113baeb4dc } from "@ember/template-compiler";
const FKLabel = template_98c06fe2fa7e4d41977bfa113baeb4dc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
